import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  RangeInput,
  Text,
} from "grommet";
import { useState } from "react";

interface IFormValue {
  percentILYGuess: number;
  filledOutForm: boolean;
}

export const PopQuizForm: React.FC<{
  setFinishedQuiz: (f: boolean) => void;
  disabled: boolean;
}> = ({ setFinishedQuiz, disabled }) => {
  const [value, setValue] = useState<IFormValue>({
    percentILYGuess: 0,
    filledOutForm: false,
  });
  const encode = (data: { [x: string]: string | number | boolean }) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        // must be the same as the form in HTML
        "form-name": "popquiz",
        // keys should be the same as field names in HTML
        ...value,
      }),
    })
      .then(() => setFinishedQuiz(true))
      .catch((error) =>
        alert("uh oh, something went wrong :(. refresh and try again?")
      );

    e.preventDefault();
  };
  return (
    <Form
      value={value}
      name="popquiz"
      onChange={(nextValue) => setValue(nextValue)}
      onSubmit={handleSubmit}
    >
      {/* For netlify ONLY */}
      <input type="hidden" name="form-name" value="popquiz" />
      <FormField
        name="percentILYGuess"
        label="What % of Asian American parents do you think say 'I love you'?"
        margin="large"
      >
        <Box flex direction="column">
          <Text textAlign="center">{`${value.percentILYGuess}%`}</Text>
          <RangeInput name="percentILYGuess" min={0} max={100} color="brand" />
        </Box>
      </FormField>
      <FormField
        name="filledOutForm"
        label={
          <span>
            Have you filled out the{" "}
            <Anchor
              href="https://forms.gle/a2oKtqzujQKSHvuB8"
              label="survey?"
              target="_blank"
            />
          </span>
        }
        margin="large"
      >
        <Box flex direction="row">
          <Text margin={{ left: "small", right: "small" }}>No</Text>
          <CheckBox name="filledOutForm" toggle />
          <Text margin={{ left: "small" }}> Yes </Text>
        </Box>
      </FormField>
      <Box alignContent="center">
        <Button
          disabled={disabled}
          type="submit"
          label="Start"
          primary
          alignSelf="center"
          margin={{ bottom: "medium" }}
        />
        {disabled && (
          <Text textAlign="center" size="small">
            Lag... Data is swirling... hang tight!
          </Text>
        )}
      </Box>
    </Form>
  );
};
