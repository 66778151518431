export const CenteredMetric: React.FC<{
  dataWithArc: any;
  centerX: any;
  centerY: any;
}> = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum: { value: number }) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "52px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};
