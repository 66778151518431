import { Box, Heading, Text, Image } from "grommet";
import _ from "lodash";
import { ContentPage } from "./ContentPage";
import {
  aggregateBarDataOnParentLove,
  aggregateDataHeatMap,
  aggregateDivergingBarEthnicity,
  aggregateDivergingBarEthnicity2,
  aggregateOneVariableBarChartData,
  aggregateOneVariablePieChartData,
  getDataOrder,
  getLabelReplacements,
  IAsianParentDatum,
} from "./DataUtils";
import { MyResponsiveBar } from "./MyResponsiveBar";
import { MyResponsiveDivergingBar } from "./MyResponsiveDivergingBar";
import { MyResponsiveHeatMap } from "./MyResponsiveHeatMap";
import { MyResponsivePie } from "./MyResponsivePie";

import eggplant from "./images/eggplatn7.png";

export const DataVisualizations: React.FC<{
  filteredData: IAsianParentDatum[];
  data: IAsianParentDatum[];
  refToScroll: React.RefObject<HTMLHeadingElement>;
}> = ({ filteredData, data, refToScroll }) => (
  <>
    <ContentPage
      title={`Do your AAPI parents say "I love you?"`}
      refToScroll={refToScroll}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">
        Be it cutting fruit, sacrifice, or putting food on the table, "love"
        isn't captured by one Western phrase. At the same time, do those words
        represent something for people who straddle multiple worlds?
      </Text>
      <MyResponsivePie
        data={aggregateOneVariablePieChartData(filteredData, "parentsLove")}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        additionalBottomMargin={40}
      />
    </ContentPage>
    <ContentPage title={`Moms say ILY`}>
      <Text size="small">
        How often do our moms' say "I love you"? Do you find those words
        meaningful? When was the last time your mom did to <i>show</i> you she
        loves you?
      </Text>
      <MyResponsivePie
        data={aggregateOneVariablePieChartData(filteredData, "motherLove")}
        includeLegend={true}
        colorScheme={[
          "#9f98ec",
          "#bba9ed",
          "#d2bbef",
          "#e5cff3",
          "#f4e4f8",
          "#fffaff",
        ]}
        labelReplacements={getLabelReplacements("motherLove") ?? undefined}
        additionalBottomMargin={60}
      />
    </ContentPage>
    <ContentPage title={`Dads say ILY`} backgroundColor={"#fff8e4"}>
      <Text size="small">
        How often do our dads' say "I love you"? Is there a difference in your
        family? When do you most feel loved by your Dad?
      </Text>
      <MyResponsivePie
        data={aggregateOneVariablePieChartData(filteredData, "fatherLove")}
        includeLegend={true}
        colorScheme={[
          "#f592bd",
          "#f6a6cf",
          "#f7bade",
          "#f8cceb",
          "#fbdff6",
          "#fff1ff",
        ]}
        labelReplacements={getLabelReplacements("fatherLove") ?? undefined}
        additionalBottomMargin={60}
      />
    </ContentPage>
    <ContentPage title={`We say ILY to our parents`}>
      <Text size="small">
        What do these words mean to you? How has that changed? What has that
        meant for your relationships?
      </Text>
      <MyResponsivePie
        data={aggregateOneVariablePieChartData(filteredData, "youLove")}
        includeLegend={true}
        colorScheme={[
          "#df98ec",
          "#e7abef",
          "#eebdf3",
          "#f4d0f7",
          "#fae2fb",
          "#fff5ff",
        ]}
        labelReplacements={getLabelReplacements("youLove") ?? undefined}
        additionalBottomMargin={60}
      />
    </ContentPage>
    <TransitionPage
      title={"ILY x Demographic"}
      backgroundColor={"#fff8e4"}
      image={eggplant}
    />

    <ContentPage title={`Does gender influence ILY's?`}>
      <Text size="small">
        Does your gender identity come with different cultural expectations for
        how you have received love?
      </Text>
      <MyResponsiveBar
        data={aggregateBarDataOnParentLove(filteredData, "gender", true)}
        grouped={true}
        keys={["ILY", "No ILY"]}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        additionalBottomMargin={20}
      />
      {/* <MyResponsiveDivergingBar
            data={aggregateBarDataOnParentLove(filteredData, "gender")}
            horizontal={true}
          /> */}
    </ContentPage>
    <ContentPage
      title={`Does age influence ILY's?`}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">
        How has your relationship with your parents changed as you walked
        through the stages of your life? Has your slice of society changed its
        understanding of your culture?
      </Text>
      <MyResponsiveBar
        data={aggregateBarDataOnParentLove(filteredData, "age", true)}
        grouped={true}
        keys={["ILY", "No ILY"]}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        labelReplacements={getLabelReplacements("age") ?? undefined}
        additionalBottomMargin={20}
      />
    </ContentPage>
    <ContentPage
      title={`Does parents' household income growing up influence ILY's?`}
    >
      <Text size="small">
        Today,{" "}
        <a href="https://www.pewresearch.org/social-trends/2018/07/12/income-inequality-in-the-u-s-is-rising-most-rapidly-among-asians/">
          Asians are the demographic with the greatest income inequality
        </a>{" "}
        in the US. What does that say about our experiences? This data is not
        representative.{" "}
        <a href="https://forms.gle/uMhJLD2vxTLVAn3S7">
          Can you help me change that?
        </a>
      </Text>
      <MyResponsiveBar
        data={aggregateBarDataOnParentLove(filteredData, "parentsIncome", true)}
        grouped={true}
        keys={["ILY", "No ILY"]}
        horizontal={true}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        labelReplacements={getLabelReplacements("parentsIncome") ?? undefined}
        additionalBottomMargin={100}
      />
    </ContentPage>
    <ContentPage
      title={`Does ethnicity influence ILY's?`}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">How does your culture uniquely express love?</Text>
      <MyResponsiveBar
        data={aggregateDivergingBarEthnicity(filteredData)}
        grouped={true}
        keys={["ILY", "No ILY"]}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        horizontal={true}
        additionalBottomMargin={0}
        additionalLeftMargin={25}
      />
    </ContentPage>
    <ContentPage title={`Does ethnicity influence Dad ILY's differently?`}>
      <Text size="small">Are fathers always different?</Text>
      <MyResponsiveBar
        data={aggregateDivergingBarEthnicity2(filteredData)}
        grouped={true}
        keys={["ILY", "No ILY"]}
        includeLegend={true}
        colorScheme={["#d2bbef", "#ffc9de"]}
        horizontal={true}
        additionalBottomMargin={0}
        additionalLeftMargin={25}
      />
    </ContentPage>
    <TransitionPage title={"Expectations"} backgroundColor={"#fff8e4"} />
    <ContentPage
      title={`Does meeting parents' expectations make us more comfortable with them?`}
    >
      <Text size="small">Tag yourself!</Text>
      <MyResponsiveHeatMap
        data={aggregateDataHeatMap(
          filteredData,
          "feelYourselfWithParents",
          "parentsExpectations",
          true
        )}
        colorScheme={"purples"}
        dim1Label={"Comfort being self with parents"}
        dim2Label={"Perceived freq. meet parents expectations"}
        dim1LabelReplacements={
          getLabelReplacements("feelYourselfWithParents") ?? undefined
        }
        dim2LabelReplacements={
          getLabelReplacements("parentsExpectations") ?? undefined
        }
      />
    </ContentPage>
    <ContentPage
      title={`Does meeting parents' expectations influence how we recommend our current careers/study?`}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">
        What are the areas of your life that they still have an influence over?
      </Text>
      <MyResponsiveHeatMap
        data={aggregateDataHeatMap(
          filteredData,
          "recommendCareer",
          "parentsExpectations",
          true
        )}
        colorScheme={"purples"}
        dim1Label={"Likelihood to recommend current career/study"}
        dim2Label={"Perceived freq. meet parents expectations"}
        dim1LabelReplacements={
          getLabelReplacements("recommendCareer") ?? undefined
        }
        dim2LabelReplacements={
          getLabelReplacements("parentsExpectations") ?? undefined
        }
      />
    </ContentPage>
    <ContentPage
      title={`How does meeting parents' expectations relate to meeting our own expectations?`}
    >
      <Text size="small">
        What do you perpetuate? What <i>will</i> you?
      </Text>
      <MyResponsiveHeatMap
        data={aggregateDataHeatMap(
          filteredData,
          "yourExpectations",
          "parentsExpectations",
          true
        )}
        colorScheme={"purples"}
        dim1Label={"Freq. meet own expectations"}
        dim2Label={"Perceived freq. meeting parents expectations"}
        dim1LabelReplacements={
          getLabelReplacements("yourExpectations") ?? undefined
        }
        dim2LabelReplacements={
          getLabelReplacements("parentsExpectations") ?? undefined
        }
      />
    </ContentPage>

    <TransitionPage title={"Self-Love"} backgroundColor={"#fff8e4"} />
    <ContentPage title={`How often do we love ourselves?`}>
      <Text size="small">Where do you find yourself in this moment?</Text>
      <MyResponsiveBar
        data={aggregateOneVariableBarChartData(filteredData, "loveYourself")}
        keys={_.uniq(data.map((d) => d["loveYourself"])).sort((a, b) => {
          const order = getDataOrder("loveYourself");
          return order[a] - order[b];
        })}
        colorScheme={["#bcbde8", "#e1d3f3", "#ffebff", "#f5b4cd", "#e67f83"]}
        disableAxisBottom={true}
        includeLegend={true}
        additionalBottomMargin={-10}
      />
    </ContentPage>
    <ContentPage
      title={`Does money we earn = self-love?`}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">What else is missing?</Text>
      <MyResponsiveHeatMap
        data={aggregateDataHeatMap(
          filteredData,
          "yourIncome",
          "loveYourself",
          true
        )}
        colorScheme={"purples"}
        dim1Label={"Your income"}
        dim2Label={"Freq. love yourself"}
        dim1LabelReplacements={getLabelReplacements("yourIncome") ?? undefined}
        dim2LabelReplacements={
          getLabelReplacements("loveYourself") ?? undefined
        }
      />
    </ContentPage>
    <ContentPage title={`How do we find self-love vs. our expectations?`}>
      <Text size="small">
        What do you <i>really</i> expect from yourself?
      </Text>
      <MyResponsiveHeatMap
        data={aggregateDataHeatMap(
          filteredData,
          "yourExpectations",
          "loveYourself",
          true
        )}
        colorScheme={"purples"}
        dim1Label={"Freq. meet own expectations"}
        dim2Label={"Freq. love yourself"}
        dim1LabelReplacements={
          getLabelReplacements("yourExpectations") ?? undefined
        }
        dim2LabelReplacements={
          getLabelReplacements("loveYourself") ?? undefined
        }
      />
    </ContentPage>
    <ContentPage
      title={`Do our parents ILY's influence our self-love?`}
      backgroundColor={"#fff8e4"}
    >
      <Text size="small">
        When do <i>you</i> love yourself the most?
      </Text>
      <MyResponsiveDivergingBar
        data={aggregateBarDataOnParentLove(filteredData, "loveYourself")}
      />
    </ContentPage>
  </>
);

const TransitionPage: React.FC<{
  title: string;
  backgroundColor?: string;
  image?: string;
}> = ({ title, backgroundColor, image }) => {
  // const trueHeight = use100vh();
  // const screenHeight = trueHeight ? `${trueHeight}px` : "100vh";

  const screenHeight = "100vh";
  const marginHeading = Boolean(image)
    ? {
        top: "xlarge",
      }
    : undefined;
  return (
    <Box
      height={screenHeight}
      width={"100%"}
      background={backgroundColor}
      pad={{ left: "medium", right: "medium" }}
      justify="center"
      alignContent="center"
      align="center"
    >
      <Heading
        alignSelf="center"
        textAlign="center"
        level={1}
        margin={marginHeading}
      >
        {title}
      </Heading>
      {Boolean(image) && <Image fit="contain" fill src={image}></Image>}
    </Box>
  );
};
