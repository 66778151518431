import { Box, Heading } from "grommet";

export const ContentPage: React.FC<{
  title: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  refToScroll?: React.RefObject<HTMLHeadingElement>;
}> = ({ title, children, backgroundColor, refToScroll }) => {
  // const size = useContext(ResponsiveContext);
  // const trueHeight = use100vh();
  // const screenHeight = trueHeight ? `${trueHeight}px` : "100vh";
  const screenHeight = "100vh";
  return (
    <Box
      ref={refToScroll}
      height={screenHeight}
      width={"100%"}
      background={backgroundColor}
      pad={{ left: "medium", right: "medium" }}
    >
      <Box
        width={{ width: "100%", max: "800px" }}
        align="center"
        alignSelf="center"
      >
        <Heading
          textAlign="center"
          level={2}
          margin={{
            top: "xlarge",
            left: "large",
            right: "large",
            bottom: "medium",
          }}
        >
          {title}
        </Heading>
      </Box>
      <Box
        width={{ width: "100%", max: "800px" }}
        flex
        align="center"
        alignSelf="center"
      >
        {children}
      </Box>
    </Box>
  );
};
