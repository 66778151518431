import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ResponsiveContext } from "grommet";
import { useContext } from "react";
import { ColorInterpolatorId } from "@nivo/colors/dist/types/schemes";
import { CUSTOM_NIVO_THEME } from "./DataUtils";

export const MyResponsiveHeatMap: React.FC<{
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  dim1Label: string;
  dim2Label: string;
  dim1LabelReplacements?: { [k: string]: string };
  dim2LabelReplacements?: { [k: string]: string };
  colorScheme?: ColorInterpolatorId | undefined;
  additionalBottomMargin?: number;
  flipY?: boolean;
}> = ({
  data,
  colorScheme,
  dim1Label,
  dim2Label,
  dim1LabelReplacements,
  dim2LabelReplacements,
  additionalBottomMargin,
  flipY,
}) => {
  const size = useContext(ResponsiveContext);
  const addBottomMargin = additionalBottomMargin ?? 0;
  const computeMax = Math.max.apply(
    Math,
    data.map((d1) =>
      Math.max.apply(
        Math,
        d1.data.map((d2) => d2.y)
      )
    )
  );
  return (
    <ResponsiveHeatMap
      theme={CUSTOM_NIVO_THEME}
      data={data}
      margin={{
        top: 60,
        right: size === "small" ? 20 : 60,
        bottom:
          size === "small" ? 140 + addBottomMargin : 120 + addBottomMargin,
        left: size === "small" ? 60 : 100,
      }}
      valueFormat=">-.2s"
      axisTop={{
        legend: dim2Label,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -32,
        legendPosition: "middle",
        format: (v) => {
          return dim2LabelReplacements !== undefined
            ? dim2LabelReplacements[v] ?? v
            : v;
        },
      }}
      axisLeft={{
        legend: dim1Label,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -60,
        legendPosition: "middle",
        legendOffset: -50,
        format: (v) => {
          return dim1LabelReplacements !== undefined
            ? dim1LabelReplacements[v] ?? v
            : v;
        },
      }}
      colors={{
        type: "sequential",
        scheme: colorScheme ? colorScheme : "blues",
        minValue: 0,
        maxValue: Math.ceil(computeMax * 1.1),
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 300,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Count →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}
    />
  );
};
