import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { ResponsiveContext } from "grommet";
import { useContext } from "react";
import { BarLegendProps } from "@nivo/bar/dist/types/types";
import { categoricalColorSchemes } from "@nivo/colors";
import { CUSTOM_NIVO_THEME } from "./DataUtils";

export const MyResponsiveBar: React.FC<{
  data: Array<BarDatum>;
  keys: string[];
  includeLegend?: boolean | undefined;
  disableAxisBottom?: boolean | undefined;
  colorScheme?: string[];
  grouped?: boolean;
  horizontal?: boolean;
  reverse?: boolean;
  labelReplacements?: { [k: string]: string };
  additionalBottomMargin?: number;
  additionalLeftMargin?: number;
}> = ({
  data,
  keys,
  includeLegend,
  disableAxisBottom,
  colorScheme,
  grouped,
  horizontal,
  reverse,
  labelReplacements,
  additionalBottomMargin,
  additionalLeftMargin,
}) => {
  const size = useContext(ResponsiveContext);
  const myColorScheme = colorScheme ?? categoricalColorSchemes.pastel1;
  const addBottomMargin = additionalBottomMargin ?? 0;
  const addLeftMargin = additionalLeftMargin ?? 0;
  const legendProps = {
    anchor: "bottom",
    dataFrom: "indexes",
    direction: "row",
    translateX: 20,
    translateY: 50,
    itemWidth: 135,
    itemHeight: 16,
    itemTextColor: "#999",
    itemDirection: "left-to-right",
    itemOpacity: 1,
    symbolSize: 10,
    symbolShape: "square",
    effects: [
      {
        on: "hover",
        style: {
          itemTextColor: "#000",
        },
      },
    ],
  };
  let legend: BarLegendProps[] = [];
  if (includeLegend) {
    if (Boolean(grouped)) {
      legend = [
        {
          dataFrom: "keys",
          data: keys.map((id, index) => ({
            color: myColorScheme[index],
            id,
            label: id,
          })),
          anchor: "bottom",
          direction: "row",
          translateY: 63,
          itemWidth: 80,
          itemHeight: 20,
          itemDirection: "left-to-right",
          symbolSize: 20,
        },
      ];
    } else {
      legend =
        data.length <= 4
          ? ([{ ...legendProps }] as BarLegendProps[])
          : ([
              {
                ...legendProps,
                itemsSpacing: 0,
                translateY: size === "small" ? 32 : 48,
                data: data
                  .slice(0, Math.floor(data.length / 2))
                  .map((cur, index) => ({
                    id: cur.label,
                    label: cur.label,
                    color: myColorScheme.slice(0, Math.floor(data.length / 2))[
                      index
                    ],
                  })),
              },
              {
                ...legendProps,
                symbolSize: 10,
                itemsSpacing: 0,
                translateY: size === "small" ? 56 : 76,
                data: data
                  .slice(Math.floor(data.length / 2))
                  .map((cur, index) => ({
                    id: cur.label,
                    label: cur.label,
                    color: myColorScheme.slice(Math.floor(data.length / 2))[
                      index
                    ],
                  })),
              },
            ] as BarLegendProps[]);
    }
  }

  console.log("bar", { data });
  return (
    <ResponsiveBar
      theme={CUSTOM_NIVO_THEME}
      data={data}
      colors={[...myColorScheme]}
      margin={{
        top: 50,
        right: 20,
        bottom:
          size === "small" ? 200 + addBottomMargin : 150 + addBottomMargin,
        left: Boolean(grouped) ? 50 + addLeftMargin : 50 + addLeftMargin,
      }}
      padding={0.3}
      keys={keys}
      indexBy={"label"}
      labelTextColor={"inherit:darker(1.4)"}
      enableGridY={!Boolean(horizontal)}
      enableGridX={Boolean(horizontal)}
      axisBottom={
        Boolean(disableAxisBottom)
          ? null
          : {
              format: (v) => {
                return labelReplacements !== undefined
                  ? labelReplacements[v] ?? v
                  : v;
              },
            }
      }
      legends={legend}
      groupMode={Boolean(grouped) ? "grouped" : "stacked"}
      layers={["grid", "axes", "bars", "markers", "legends", "annotations"]}
      layout={Boolean(horizontal) ? "horizontal" : "vertical"}
      reverse={reverse}
      enableLabel={false}
      axisLeft={{
        format: (v) => {
          return labelReplacements !== undefined
            ? labelReplacements[v] ?? v
            : v;
        },
      }}
    />
  );
};
