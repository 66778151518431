import { useContext, useEffect, useMemo, useRef, useState } from "react";
import data from "./data/asn-am-4-4.json";

import {
  Box,
  grommet,
  Grommet,
  Heading,
  Text,
  Image,
  Anchor,
  Button,
  Select,
  ResponsiveContext,
} from "grommet";
import {
  ETHNICITY_INDEX_START,
  IAsianParentDatum,
  standardizeData,
} from "./DataUtils";
import _ from "lodash";
import { myTheme } from "./theme";
import { deepMerge } from "grommet/utils";
import CountUp from "react-countup";

import orange from "./images/onrge-nob.png";
import { PopQuizForm } from "./PopQuizForm";
import { ContentPage } from "./ContentPage";
import { DataVisualizations } from "./DataVisualizations";
import React from "react";

import { TwitterIcon, TwitterShareButton } from "react-share";

function AllCharts() {
  const standardizedData = standardizeData(data);
  const mergedTheme = deepMerge(grommet, myTheme);
  const DUMMY_RESPONSE_COUNT = data.length;
  const [finishedQuiz, setFinishedQuiz] = useState(false);
  useEffect(() => {
    if (finishedQuiz) {
      scrollToData();
    }
  }, [finishedQuiz]);

  const scrollToData = () => {
    if (childReference.current !== null) {
      childReference.current.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }
  };
  const childReference = useRef<HTMLHeadingElement>(null);

  const [selectedFilters, setSelectedFilters] = useState<IFilterOptions>({
    selectedAge: null,
    selectedEthnicity: null,
    selectedGender: null,
  });

  // age
  const ageOptions = useMemo(
    () => _.uniq(data.map((d) => d.age)).sort((a, b) => a.localeCompare(b)),
    []
  );
  // const ageOptionIndexToValue = useMemo(
  //   () => new Map(ageOptions.map((age, i) => [i, age])),
  //   [ageOptions]
  // );
  // const [selectedAgeOptionIndices, setSelectedAgeOptionIndices] =
  //   React.useState<Array<number>>(_.range(ageOptions.length));
  // const selectedAgeOptions = useMemo(
  //   () =>
  //     new Map(
  //       selectedAgeOptionIndices.map((ageOptionIndex) => [
  //         ageOptionIndexToValue.get(ageOptionIndex),
  //         true,
  //       ])
  //     ),
  //   [ageOptionIndexToValue, selectedAgeOptionIndices]
  // );

  // gender
  const genderOptions = useMemo(
    () => _.uniq(data.map((d) => d.gender)).sort((a, b) => a.localeCompare(b)),
    []
  );
  // const genderOptionIndexToValue = useMemo(
  //   () => new Map(genderOptions.map((gender, i) => [i, gender])),
  //   [genderOptions]
  // );
  // const [selectedGenderOptionIndices, setSelectedGenderOptionIndices] =
  //   React.useState<Array<number>>(_.range(genderOptions.length));
  // const selectedGenderOptions = useMemo(
  //   () =>
  //     new Map(
  //       selectedGenderOptionIndices.map((genderOptionIndex) => [
  //         genderOptionIndexToValue.get(genderOptionIndex),
  //         true,
  //       ])
  //     ),
  //   [genderOptionIndexToValue, selectedGenderOptionIndices]
  // );

  // ethnicity
  const ethnicityOptions = Object.keys(standardizedData[0])
    .splice(ETHNICITY_INDEX_START)
    .sort();

  // filters
  // const filterByAge = standardizedData.filter((d) =>
  //   selectedAgeOptions.has(d.age)
  // );
  const filterByAge = standardizedData.filter((d) =>
    Boolean(selectedFilters.selectedAge)
      ? selectedFilters.selectedAge === d.age
      : true
  );
  // const filterByGender = filterByAge.filter((d) =>
  //   selectedGenderOptions.has(d.gender)
  // );
  const filterByGender = filterByAge.filter((d) =>
    Boolean(selectedFilters.selectedGender)
      ? selectedFilters.selectedGender === d.gender
      : true
  );

  const filterByEthnicity = filterByGender.filter((d) =>
    selectedFilters.selectedEthnicity !== null
      ? d[selectedFilters.selectedEthnicity as keyof IAsianParentDatum]
      : true
  );

  const filteredData = filterByEthnicity;
  console.log({ selectedFilters });

  return (
    <Grommet theme={mergedTheme}>
      <Box
        background="#fff8e4"
        height={"95vh"}
        width={"100%"}
        pad={{ left: "medium", right: "medium" }}
        alignContent="center"
        align="center"
      >
        <Box margin={{ top: "large" }} align="center" alignContent="center">
          <Heading
            level="1"
            margin={{ bottom: "none" }}
            style={{ fontWeight: 600 }}
          >
            Asian Americans
          </Heading>
          <Heading
            level="2"
            style={{ fontWeight: 600 }}
            margin={{ top: "small", bottom: "small" }}
          >
            and Our Parents
          </Heading>
          <Text textAlign="center">
            a collection of our experiences in data to understand who we are
          </Text>
          <Text></Text>
          <CountUp
            start={DUMMY_RESPONSE_COUNT}
            end={DUMMY_RESPONSE_COUNT}
            duration={10}
            suffix=" stories shared"
          />
          <Text textAlign="center" margin={{ bottom: "large" }}>
            (updated 04/04/22)
          </Text>
        </Box>
        <Image fit="contain" fill src={orange}></Image>
        <Box alignContent="center" margin={{ bottom: "large", top: "large" }}>
          <Text size="small" textAlign="center">
            By{" "}
            <Anchor
              href="mailto:christopher.s.chen@gmail.com"
              label="Christopher Chen, "
              target="_blank"
            />
            <Anchor
              href="https://www.instagram.com/shalombrushesss/"
              label="Twitter"
              target="_blank"
            />
          </Text>
          <Text size="small" textAlign="center">
            Art by{" "}
            <Anchor
              href="https://www.instagram.com/shalombrushesss/"
              label="@shalombrushesss, "
              target="_blank"
            />
            <Anchor
              href="https://www.instagram.com/cmyc_s/"
              label="@cmyc_s"
              target="_blank"
            />
          </Text>
        </Box>
        <DisplayIfDevice ifMobile={true}></DisplayIfDevice>
      </Box>
      <ContentPage title={`About`}>
        <Text size="small" margin={{ bottom: "medium" }}>
          This all started with a simple conversation with two close friends: Do
          your parents say "I love you?" I was surprised to find my answers were
          different from that of my friends. Those words weren't something that
          I grew up with - they weren't from a culture that any of our parents
          brought with them to America.
        </Text>
        <Text size="small" margin={{ bottom: "medium" }}>
          When I talk about Asian American parent ILY's or any cultural
          experience, I often find it difficult to separate what is unique to
          myself, my circle, or what is portrayed in mainstream media. Even more
          so when they are very personal and hit close to home.
        </Text>
        <Text size="small" margin={{ bottom: "medium" }}>
          Can we reflect on our understanding of the Asian American experience
          and how we belong, or don't, through data? Can that help us inform the
          conversations we have with people we care about? I asked the Asian
          American people I knew nine questions about: our relationships with
          parents, expectations, and self-love.
        </Text>
        <Box flex direction="row-responsive"></Box>
      </ContentPage>
      <ContentPage title={"Data Guide"} backgroundColor={"#fff8e4"}>
        <Box flex direction="column">
          <Box>
            <Heading level={4}> Share first: </Heading>
            <Text size="small">
              Have you responded to the survey yourself if you identify as Asian
              American/AANHPI? The following visualizations will be more
              meaningful when you've answered these questions for yourself.
            </Text>
            <Heading
              alignSelf="center"
              textAlign="center"
              level={3}
              margin={{ top: "large", bottom: "large" }}
            >
              <Anchor
                style={{ textDecoration: "underline" }}
                href="https://forms.gle/uRQv3EjBL2wqxPVV8"
                label="Fill survey"
                target="_blank"
              />
            </Heading>
          </Box>
          <Box>
            <Heading level={4}> How to read this data: </Heading>
            <Text size="small">
              Each of the following sections will present a data visualization
              along with some guiding questions. While the sample size is small,
              I haven't provided conclusions or statistical analyses. Maybe the
              largest bias is in what kind of person is willing to respond.{" "}
              <b>
                My rule of thumb is: find yourself, and then find other people
                you care about.
              </b>{" "}
              Even better, do this with a friend! Then based on each graph, is
              there a conversation you'd like to have? I'd love if you found
              someone to share this with.
            </Text>
          </Box>
        </Box>
      </ContentPage>
      <ContentPage title={"Before we start"}>
        <PopQuizForm
          setFinishedQuiz={setFinishedQuiz}
          disabled={finishedQuiz}
        />
      </ContentPage>
      {finishedQuiz && (
        <DataVisualizations
          filteredData={filteredData}
          data={standardizedData}
          refToScroll={childReference}
        />
      )}
      {finishedQuiz && (
        <ContentPage title={`Do your AAPI parents say "I love you?"`}>
          <Text size="small">
            If you found this meaningful, please go and have a conversation with
            someone!{" "}
            <Anchor
              href="https://forms.gle/uRQv3EjBL2wqxPVV8"
              label="Link to original survey"
              target="_blank"
            />
          </Text>
          <Box margin="large">
            <TwitterShareButton
              title={
                "Asian Americans and Our Parents | a living collection of our experiences in data to understand and belong"
              }
              via={""}
              hashtags={[]}
              related={["chrismychen"]}
              url={"https://asianamerican.fyi"}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Box>
          <Box alignContent="center" align="center">
            <Heading level={3}> See it all again, with filters </Heading>
            <HardcodeDemographicSelectors
              ageOptions={ageOptions}
              genderOptions={genderOptions}
              ethnicityOptions={ethnicityOptions}
              selectedFilters={selectedFilters}
              setFilters={setSelectedFilters}
            />
            <Text size="small">
              B/c sample sizes are small, you can only select 1 option/time.
              Options with &lt;10 data points are disabled. Ask your friends if
              you'd like to see more!
            </Text>
            <Button
              margin={{ top: "medium" }}
              label="BACK TO TOP"
              onClick={scrollToData}
            />
          </Box>
        </ContentPage>
      )}
    </Grommet>
  );
}

interface IFilterOptions {
  selectedAge: string | null;
  selectedGender: string | null;
  selectedEthnicity: string | null;
}

const HardcodeDemographicSelectors: React.FC<{
  ageOptions: string[];
  genderOptions: string[];
  ethnicityOptions: string[];
  selectedFilters: IFilterOptions;
  setFilters: (f: IFilterOptions) => void;
}> = ({
  ageOptions,
  genderOptions,
  ethnicityOptions,
  selectedFilters,
  setFilters,
}) => {
  const resetOtherFilters = {
    selectedAge: null,
    selectedGender: null,
    selectedEthnicity: null,
  };

  return (
    <>
      Age
      <Select
        options={ageOptions}
        value={selectedFilters.selectedAge ?? ""}
        onChange={({ option }) =>
          setFilters({ ...resetOtherFilters, selectedAge: option })
        }
        disabled={[2, 3, 4, 5]}
      />
      Gender
      <Select
        options={genderOptions}
        value={selectedFilters.selectedGender ?? ""}
        onChange={({ option }) =>
          setFilters({ ...resetOtherFilters, selectedGender: option })
        }
        disabled={[0, 2]}
      />
      Ethnicity
      <Select
        options={ethnicityOptions}
        value={selectedFilters.selectedEthnicity ?? ""}
        onChange={({ option }) =>
          setFilters({ ...resetOtherFilters, selectedEthnicity: option })
        }
        disabled={[0, 1, 5, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17]}
      />
    </>
  );
};

const DisplayIfDevice: React.FC<{
  ifMobile: boolean;
  children?: React.ReactNode;
}> = ({ ifMobile, children }) => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "small";
  if (ifMobile && isMobile) {
    return <>{children}</>;
  } else if (!ifMobile && !isMobile) {
    return <>{children}</>;
  }
  return null;
};
// const AllDemographicSelectors: React.FC<{
//   selectedAgeOptionIndices: number[];
//   ageOptions: string[];
//   setSelectedAgeOptionIndices: (value: SetStateAction<number[]>) => void;
//   selectedGenderOptionIndices: number[];
//   genderOptions: string[];
//   setSelectedGenderOptionIndices: (value: SetStateAction<number[]>) => void;
// }> = ({
//   selectedAgeOptionIndices,
//   ageOptions,
//   setSelectedAgeOptionIndices,
//   selectedGenderOptionIndices,
//   genderOptions,
//   setSelectedGenderOptionIndices,
// }) => (
//   <>
//     <DemographicSelector
//       label={"ages"}
//       selectedIndices={selectedAgeOptionIndices}
//       options={ageOptions}
//       onChange={({ selected: nextSelected }) => {
//         setSelectedAgeOptionIndices(nextSelected);
//       }}
//     />
//     <DemographicSelector
//       label={"gender identities"}
//       selectedIndices={selectedGenderOptionIndices}
//       options={genderOptions}
//       onChange={({ selected: nextSelected }) => {
//         setSelectedGenderOptionIndices(nextSelected);
//       }}
//     />
//   </>
// );

// const Option: React.FC<{ value: string; selected: boolean }> = React.memo(
//   ({ value, selected }) => (
//     <Box direction="row" gap="small" align="center" pad="xsmall">
//       <CheckBox checked={selected} onChange={() => {}} />
//       {value}
//     </Box>
//   )
// );

// const DemographicSelector: React.FC<{
//   label: string;
//   selectedIndices: Array<number>;
//   options: Array<string>;
//   onChange: (...args: any[]) => void;
//   multiSelect?: boolean;
// }> = ({ label, selectedIndices, options, onChange, multiSelect }) => {
//   return (
//     <>
//       {label}
//       <Select
//         multiple
//         closeOnChange={false}
//         messages={{
//           multiple:
//             selectedIndices.length === options.length ? "all" : "multiple",
//         }}
//         placeholder="select an option..."
//         selected={selectedIndices}
//         options={options}
//         dropHeight="medium"
//         onChange={onChange}
//       >
//         {(option: string, index: number) => (
//           <Option
//             value={option}
//             selected={selectedIndices.indexOf(index) !== -1}
//           />
//         )}
//       </Select>
//     </>
//   );
// };

export default AllCharts;
