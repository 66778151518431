// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,

import { ResponsivePie } from "@nivo/pie";
import { LegendProps } from "@nivo/legends/dist/types/types";
import { CUSTOM_NIVO_THEME, IPieChartDatum } from "./DataUtils";
import { CenteredMetric } from "./PieCenteredMetric";
import React, { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { categoricalColorSchemes } from "@nivo/colors";

// you'll often use just a few of them.
export const MyResponsivePie: React.FC<{
  data: Array<IPieChartDatum>;
  includeLegend?: boolean | undefined | null;
  colorScheme?: string[];
  labelReplacements?: { [k: string]: string };
  additionalBottomMargin?: number;
}> = ({
  data,
  includeLegend,
  colorScheme,
  labelReplacements,
  additionalBottomMargin,
}) => {
  const size = useContext(ResponsiveContext);
  const myColorScheme = colorScheme ?? categoricalColorSchemes.pastel1;
  const bottomMarginAddition = additionalBottomMargin ?? 0;
  const legendProps = {
    anchor: "bottom",
    direction: "row",
    justify: false,
    translateX: 20,
    translateY: 0,
    itemsSpacing: 0,
    itemWidth: 130,
    itemHeight: 16,
    itemTextColor: "#999",
    itemDirection: "left-to-right",
    itemOpacity: 1,
    symbolSize: 10,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemTextColor: "#000",
        },
      },
    ],
  };
  const legend = includeLegend
    ? data.length <= 4
      ? ([
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 30,
            translateY: size === "small" ? 45 : 50,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
            data: data.map((cur, idx) => ({
              id: cur.id,
              label:
                labelReplacements && labelReplacements[cur.label]
                  ? `${labelReplacements[cur.label]}`
                  : cur.label,
              color: myColorScheme[idx],
            })),
          },
        ] as LegendProps[])
      : ([
          {
            ...legendProps,
            itemsSpacing: 0,
            translateX: 30,
            translateY: size === "small" ? 24 : 48,
            data: data
              .slice(0, Math.floor(data.length / 2))
              .map((cur, index) => ({
                id: cur.id,
                label:
                  labelReplacements && labelReplacements[cur.label]
                    ? `${labelReplacements[cur.label]}`
                    : cur.label,
                color: myColorScheme.slice(0, Math.floor(data.length / 2))[
                  index
                ],
              })),
          },
          {
            ...legendProps,
            symbolSize: 10,
            itemsSpacing: 0,
            translateX: 30,
            translateY: size === "small" ? 48 : 76,
            data: data.slice(Math.floor(data.length / 2)).map((cur, index) => ({
              id: cur.id,
              label:
                labelReplacements && labelReplacements[cur.label]
                  ? `${labelReplacements[cur.label]}`
                  : cur.label,
              color: myColorScheme.slice(Math.floor(data.length / 2))[index],
            })),
          },
        ] as LegendProps[])
    : [];
  return (
    <ResponsivePie
      theme={CUSTOM_NIVO_THEME}
      data={data}
      colors={[...myColorScheme]}
      margin={{
        top: size === "small" ? 0 : 30,
        right: 20,
        bottom:
          size === "small"
            ? 140 + bottomMarginAddition
            : 200 + bottomMarginAddition,
        left: 20,
      }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLabel={(d) => {
        if (labelReplacements && labelReplacements[d.label]) {
          return `${labelReplacements[d.label]} (${d.value})`;
        }
        return `${d.label} (${d.value})`;
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsDiagonalLength={0}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={legend}
      layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
    />
  );
};
