import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllCharts from "./AllCharts";

export default function App1() {
  return (
    <Router>
      {/*
          A <Routes> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Routes> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Routes>
        <Route path="/" element={<AllCharts />} />
        <Route path="/data" element={<AllCharts />} />
        <Route path="/about" element={<About />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}
