import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { ResponsiveContext } from "grommet";
import { useContext } from "react";
import { colorShade, CUSTOM_NIVO_THEME } from "./DataUtils";

export const MyResponsiveDivergingBar: React.FC<{
  data: Array<BarDatum>;
  disableAxisBottom?: boolean | undefined;
  horizontal?: boolean | undefined;
  colorScheme?: string[];
  labelReplacements?: { [k: string]: string };
}> = ({
  data,
  disableAxisBottom,
  horizontal,
  colorScheme,
  labelReplacements,
}) => {
  const size = useContext(ResponsiveContext);
  const myColorScheme = colorScheme ?? ["#bcbde8", "#f5b4cd"];
  const tempMaxes = data.map((d) =>
    Math.max(Math.abs(Number(d["ILY"])), Math.abs(Number(d["No ILY"])))
  );
  const calcMax = Math.ceil(1 * Math.max.apply(Math, tempMaxes));
  const props = {
    minValue: -calcMax,
    maxValue: calcMax,
    valueFormat: (value: number) => `${Math.abs(value)}`,
    labelTextColor: "inherit:darker(1.2)",
    axisLeft: null,
    axisRight: {
      format: (v: number) => `${v}`,
    },
    axisBottom: Boolean(disableAxisBottom)
      ? null
      : Boolean(horizontal)
      ? {
          format: (v: number) => `${Math.abs(v)}`,
        }
      : undefined,
    markers: Boolean(horizontal)
      ? [
          {
            axis: "x",
            value: 0,
            lineStyle: { strokeOpacity: 0 },
            textStyle: { fill: colorShade(myColorScheme[0], -40) },
            legend: "Parents ILY",
            legendPosition: "top-right",
            legendOrientation: "horizontal",
            legendOffsetX: 60,
            legendOffsetY: -10,
          } as const,
          {
            axis: "x",
            value: 0,
            lineStyle: {
              stroke: colorShade(myColorScheme[1], -40),
              strokeWidth: 1,
            },
            textStyle: { fill: colorShade(myColorScheme[1], -40) },
            legend: "No ILY",
            legendPosition: "top-left",
            legendOrientation: "horizontal",
            legendOffsetX: 60,
            legendOffsetY: -10,
          } as const,
        ]
      : [
          {
            axis: "y",
            value: 0,
            lineStyle: { strokeOpacity: 0 },
            textStyle: { fill: colorShade(myColorScheme[0], -40) },
            legend: "Parents ILY",
            legendPosition: "top-left",
            legendOrientation: "vertical",
            legendOffsetY: 40,
          } as const,
          {
            axis: "y",
            value: 0,
            lineStyle: {
              stroke: colorShade(myColorScheme[1], -40),
              strokeWidth: 1,
            },
            textStyle: { fill: colorShade(myColorScheme[1], -40) },
            legend: "No ILY",
            legendPosition: "bottom-left",
            legendOrientation: "vertical",
            legendOffsetY: 60,
          } as const,
        ],
  };
  const newKeys = ["ILY", "No ILY"];
  return (
    <ResponsiveBar
      theme={CUSTOM_NIVO_THEME}
      margin={{
        top: 50,
        right: horizontal ? 80 : 50,
        bottom: size === "small" ? 140 : 150,
        left: 20,
      }}
      padding={0.3}
      data={data}
      keys={newKeys}
      indexBy={"label"}
      enableGridY={Boolean(horizontal) ? false : true}
      enableGridX={Boolean(horizontal) ? true : false}
      colors={myColorScheme}
      layout={Boolean(horizontal) ? "horizontal" : "vertical"}
      enableLabel={false}
      {...props}
    />
  );
};
