import { ThemeType } from "grommet";

export const myTheme: ThemeType = {
  global: {
    font: {
      family: "'Fira Code', sans-serif;",
    },
    colors: {
      brand: {
        light: "#111111",
      },
      focus: "#111111",
    },
  },
  heading: {
    extend: () => `font-family: 'Playfair Display', serif`,
  },
  paragraph: {
    medium: {
      maxWidth: "624px",
    },
  },
  formField: {
    border: {
      color: "transparent",
    },
  },
};
