import { BarDatum } from "@nivo/bar";
import _ from "lodash";

export interface IPieChartDatum {
  id: string;
  label: string;
  value: number;
}

export interface IRawAsianParentDatum {
  parentsLove: string;
  motherLove: string;
  fatherLove: string;
  youLove: string;
  parentsExpectations: string;
  yourExpectations: string;
  recommendCareer: number;
  loveYourself: string;
  feelYourselfWithParents: string;
  satisfactionParentsRelationship: string;
  age: string;
  gender: string;
  parentsEducation: string;
  parentsIncome: string;
  yourIncome: string;
  growUpState: string;
  currentState: string;
  ethnicities: string;
  chinese: number;
  indian: number;
  taiwanese: number;
  filipino: number;
  nepalese: number;
  indonesian: number;
  korean: number;
  vietnamese: number;
  bangladeshi: number;
  cambodian: number;
  pakistani: number;
  japanese: number;
  mongolian: number;
  white: number;
  malaysian: number;
  jewish: number;
  singaporean: number;
}

export interface IAsianParentDatum {
  parentsLove: string;
  motherLove: string;
  fatherLove: string;
  youLove: string;
  parentsExpectations: string;
  yourExpectations: string;
  recommendCareer: number;
  loveYourself: string;
  feelYourselfWithParents: string;
  satisfactionParentsRelationship: string;
  age: string;
  gender: string;
  parentsEducation: string;
  parentsIncome: string;
  yourIncome: string;
  growUpState: string;
  currentState: string;
  ethnicities: string;
  chinese: boolean;
  indian: boolean;
  taiwanese: boolean;
  filipino: boolean;
  nepalese: boolean;
  indonesian: boolean;
  korean: boolean;
  vietnamese: boolean;
  bangladeshi: boolean;
  cambodian: boolean;
  pakistani: boolean;
  japanese: boolean;
  mongolian: boolean;
  white: boolean;
  malaysian: boolean;
  jewish: boolean;
  singaporean: boolean;
}

export function standardizeData(
  data: Array<IRawAsianParentDatum>
): Array<IAsianParentDatum> {
  return data.map((d) => ({
    ...d,
    gender: d.gender ?? "",
    chinese: Boolean(d.chinese),
    indian: Boolean(d.indian),
    taiwanese: Boolean(d.taiwanese),
    filipino: Boolean(d.filipino),
    nepalese: Boolean(d.nepalese),
    indonesian: Boolean(d.indonesian),
    korean: Boolean(d.korean),
    vietnamese: Boolean(d.vietnamese),
    bangladeshi: Boolean(d.bangladeshi),
    cambodian: Boolean(d.cambodian),
    pakistani: Boolean(d.pakistani),
    japanese: Boolean(d.japanese),
    mongolian: Boolean(d.mongolian),
    white: Boolean(d.white),
    malaysian: Boolean(d.malaysian),
    jewish: Boolean(d.jewish),
    singaporean: Boolean(d.singaporean),
  }));
}

// PRAYGE i type these fks correctly or doom rn :P
// lower number equals first in current formula, a value - b value
const ORDER_YES_NO: { [k: string]: number } = {
  No: 1,
  Yes: 0,
};

const ORDER_DISCRETE_TIME_FREQUENCY: { [k: string]: number } = {
  Never: 5,
  "Once a year": 4,
  "Once every few months": 3,
  Monthly: 2,
  Weekly: 1,
  Daily: 0,
};

const ORDER_INCOME: { [k: string]: number } = {
  "Under $15,000": 0,
  "$15,000-$24,999": 1,
  "$24,999-$34,999": 2,
  "$35,000 to $49,999": 3,
  "$50,000-$74,999": 4,
  "$75,000-$99,999": 5,
  "$100,000-$149,999": 6,
  "$150,000-$199,999": 7,
  "$200,000 and over": 8,
};

const ORDER_GENDER: { [k: string]: number } = {
  Woman: 0,
  Man: 1,
  "Non-binary": 2,
};

const ORDER_AGE: { [k: string]: number } = {
  "Under 18 years old": 0,
  "18-24 years old": 1,
  "25-34 years old": 2,
  "35-44 years old": 3,
  "45-54 years old": 4,
  "55-64 years old": 5,
  "65-74 years old": 6,
  "75 years or older": 7,
};

const ORDER_QUALITATIVE_FREQUENCY: { [k: string]: number } = {
  Never: 0,
  Rarely: 1,
  Occasionally: 2,
  Often: 3,
  Always: 4,
};

const ORDER_DIFFICULTY: { [k: string]: number } = {
  "Very difficult": 0,
  "Moderately difficult": 1,
  "Neither easy nor difficult": 2,
  "Moderately easy": 3,
  "Very easy": 4,
};

const ORDER_NUMBER_SCORE: { [k: string]: number } = {
  "1": 0,
  "2": 1,
  "3": 2,
  "4": 3,
  "5": 4,
};

const SORTABLE_DIMENSIONS: Array<keyof IAsianParentDatum> = [
  "parentsLove",
  "motherLove",
  "fatherLove",
  "youLove",
  "parentsIncome",
  "yourIncome",
  "gender",
  "age",
  "loveYourself",
  "yourExpectations",
  "parentsExpectations",
  "feelYourselfWithParents",
  "recommendCareer",
];

export function getDataOrder(dimension: keyof IAsianParentDatum) {
  switch (dimension) {
    case "parentsLove": {
      return ORDER_YES_NO;
    }
    case "motherLove": {
      return ORDER_DISCRETE_TIME_FREQUENCY;
    }
    case "fatherLove": {
      return ORDER_DISCRETE_TIME_FREQUENCY;
    }
    case "youLove": {
      return ORDER_DISCRETE_TIME_FREQUENCY;
    }
    case "parentsIncome": {
      return ORDER_INCOME;
    }
    case "yourIncome": {
      return ORDER_INCOME;
    }
    case "gender": {
      return ORDER_GENDER;
    }
    case "age": {
      return ORDER_AGE;
    }
    case "loveYourself": {
      return ORDER_QUALITATIVE_FREQUENCY;
    }
    case "yourExpectations": {
      return ORDER_QUALITATIVE_FREQUENCY;
    }
    case "parentsExpectations": {
      return ORDER_QUALITATIVE_FREQUENCY;
    }
    case "feelYourselfWithParents": {
      return ORDER_DIFFICULTY;
    }
    case "recommendCareer": {
      return ORDER_NUMBER_SCORE;
    }
    default:
      throw new Error("oh god doom");
  }
}
const LABEL_REPLACE_DIMENSIONS: Array<keyof IAsianParentDatum> = [
  "parentsLove",
  "motherLove",
  "fatherLove",
  "youLove",
  "parentsIncome",
  "yourIncome",
  "gender",
  "age",
  "loveYourself",
  "yourExpectations",
  "parentsExpectations",
  "feelYourselfWithParents",
  "recommendCareer",
];

// PRAYGE i type these fks correctly or doom rn :P
// lower number equals first in current formula, a value - b value
const LABEL_REPLACE_YES_NO: { [k: string]: string } = {
  No: "No",
  Yes: "Yes",
};

const LABEL_REPLACE_DISCRETE_TIME_FREQUENCY: { [k: string]: string } = {
  Never: "Never",
  "Once a year": "Yearly",
  "Once every few months": "NMonthly",
  Monthly: "Monthly",
  Weekly: "Weekly",
  Daily: "Daily",
};

const LABEL_REPLACE_INCOME: { [k: string]: string } = {
  "Under $15,000": "<$15k",
  "$15,000-$24,999": "$15k",
  "$24,999-$34,999": "$25k",
  "$35,000 to $49,999": "$35k",
  "$50,000-$74,999": "$50k",
  "$75,000-$99,999": "$75k",
  "$100,000-$149,999": "$100k",
  "$150,000-$199,999": "$150k",
  "$200,000 and over": ">$200k",
};

const LABEL_REPLACE_GENDER: { [k: string]: string } = {
  Woman: "Woman",
  Man: "Man",
  "Non-binary": "Non-binary",
};

const LABEL_REPLACE_AGE: { [k: string]: string } = {
  "Under 18 years old": "<18",
  "18-24 years old": "18-24",
  "25-34 years old": "25-34",
  "35-44 years old": "35-44",
  "45-54 years old": "45-54",
  "55-64 years old": "55-64",
  "65-74 years old": "65-74",
  "75 years or older": ">75",
};

const LABEL_REPLACE_QUALITATIVE_FREQUENCY: { [k: string]: string } = {
  Never: "Never",
  Rarely: "Rarely",
  Occasionally: "Ocnl",
  Often: "Often",
  Always: "Always",
};

const LABEL_REPLACE_DIFFICULTY: { [k: string]: string } = {
  "Very difficult": "Very d",
  "Moderately difficult": "Mod. d",
  "Neither easy nor difficult": "Not e/d",
  "Moderately easy": "Mod. e",
  "Very easy": "Very e",
};

const LABEL_REPLACE_NUMBER_SCORE: { [k: string]: string } = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
};

export function getLabelReplacements(dimension: keyof IAsianParentDatum) {
  if (LABEL_REPLACE_DIMENSIONS.includes(dimension)) {
    switch (dimension) {
      case "parentsLove": {
        return LABEL_REPLACE_YES_NO;
      }
      case "motherLove": {
        return LABEL_REPLACE_DISCRETE_TIME_FREQUENCY;
      }
      case "fatherLove": {
        return LABEL_REPLACE_DISCRETE_TIME_FREQUENCY;
      }
      case "youLove": {
        return LABEL_REPLACE_DISCRETE_TIME_FREQUENCY;
      }
      case "parentsIncome": {
        return LABEL_REPLACE_INCOME;
      }
      case "yourIncome": {
        return LABEL_REPLACE_INCOME;
      }
      case "gender": {
        return LABEL_REPLACE_GENDER;
      }
      case "age": {
        return LABEL_REPLACE_AGE;
      }
      case "loveYourself": {
        return LABEL_REPLACE_QUALITATIVE_FREQUENCY;
      }
      case "yourExpectations": {
        return LABEL_REPLACE_QUALITATIVE_FREQUENCY;
      }
      case "parentsExpectations": {
        return LABEL_REPLACE_QUALITATIVE_FREQUENCY;
      }
      case "feelYourselfWithParents": {
        return LABEL_REPLACE_DIFFICULTY;
      }
      case "recommendCareer": {
        return LABEL_REPLACE_NUMBER_SCORE;
      }
      default:
        throw new Error("oh god doom labels");
    }
  }
  return null;
}

export function aggregateOneVariablePieChartData(
  data: Array<IAsianParentDatum>,
  dimension: keyof IAsianParentDatum
): Array<IPieChartDatum> {
  const filteredNonresponseData = data.filter((d) => d[dimension] !== "");
  const groups = _.groupBy(filteredNonresponseData, dimension);
  const aggregate = Object.entries(groups).map(([value, entries]) => ({
    id: value,
    label: value,
    value: entries.length,
  }));
  if (SORTABLE_DIMENSIONS.includes(dimension)) {
    const orderLookup = getDataOrder(dimension);
    aggregate.sort((a, b) => {
      return orderLookup[a.label] - orderLookup[b.label];
    });
  }
  return aggregate;
}

export function aggregateOneVariableBarChartData(
  data: Array<IAsianParentDatum>,
  dimension: keyof IAsianParentDatum
): BarDatum[] {
  const filteredNonresponseData = data.filter((d) => d[dimension] !== "");
  const groups = _.groupBy(filteredNonresponseData, dimension);
  const aggregate = Object.entries(groups).map(([value, entries]) => ({
    label: value,
    [value]: entries.length,
  }));
  if (SORTABLE_DIMENSIONS.includes(dimension)) {
    const orderLookup = getDataOrder(dimension);
    aggregate.sort((a, b) => {
      return orderLookup[a.label] - orderLookup[b.label];
    });
  }
  return aggregate;
}

export function aggregateTwoVariableDataBar(
  data: Array<IAsianParentDatum>,
  dim1: keyof IAsianParentDatum,
  dim2: keyof IAsianParentDatum
) {
  const orderLookupDim1 = getDataOrder(dim1);
  const dim1Groups = Object.entries(_.groupBy(data, dim1)).sort((a, b) => {
    return orderLookupDim1[a[0]] - orderLookupDim1[b[0]];
  });
  const orderLookupDim2 = getDataOrder(dim2);
  const aggregatedValues = dim1Groups.map(([dim1Value, dim1Entries]) => {
    const dim2Breakdown = Object.entries(_.groupBy(dim1Entries, dim2))
      .map(([dim2Value, dim2Entries]) => {
        return {
          [dim2Value]: dim2Entries.length,
        };
      })
      .sort((a, b) => {
        return orderLookupDim2[a[0]] - orderLookupDim2[b[0]];
      });
    const dim2join = dim2Breakdown.reduce(
      (accum, v) => ({ ...accum, ...v }),
      {}
    );
    return {
      name: dim1Value,
      ...dim2join,
    };
  });
  return aggregatedValues;
}

// interface asdf {
//   label: string;
//   [v: string]: number;
//

export function aggregateDataHeatMap(
  data: Array<IAsianParentDatum>,
  dim1: keyof IAsianParentDatum,
  dim2: keyof IAsianParentDatum,
  flipDim2?: boolean
) {
  const dim1Order = getDataOrder(dim1);
  const dim2Order = getDataOrder(dim2);
  const dim1Keys = Object.keys(dim1Order);
  dim1Keys.sort((a, b) => {
    if (flipDim2) {
      return dim1Order[b] - dim1Order[a];
    }
    return dim1Order[a] - dim1Order[b];
  });
  const dim2Keys = Object.keys(dim2Order);
  dim2Keys.sort((a, b) => {
    return dim2Order[a] - dim2Order[b];
  });
  const aggregate = dim1Keys.map((d1) => {
    const dataSubgroup = dim2Keys.map((d2) => {
      const count = data.filter(
        (d) => String(d[dim1]) === String(d1) && String(d[dim2]) === String(d2)
      ).length;
      return {
        x: d2,
        y: count,
      };
    });
    return {
      id: d1,
      data: dataSubgroup,
    };
  });
  return aggregate;
}

export function aggregateBarDataOnParentLove(
  data: Array<IAsianParentDatum>,
  dim: keyof IAsianParentDatum,
  notDiverging?: boolean
) {
  const parentsLoveFilteredData = data.filter((d) => d.parentsLove === "Yes");
  const parentsNotLoveFilteredData = data.filter((d) => d.parentsLove === "No");
  //   {
  //     [k: string]: Array<{ label: string } & { [v: string]: number }>;
  //   }
  const tempObj = {} as _.Dictionary<BarDatum[]>;
  aggregateOneVariableBarChartData(parentsLoveFilteredData, dim).forEach(
    (d) => {
      if (d[d.label] in tempObj) {
        console.log("???");
      } else {
        tempObj[d.label] = [
          {
            label: d.label,
            [d.label]: d[d.label],
          },
          {
            // this is a dummy, in case other list doesn't have value
            label: d.label,
            [d.label]: 0,
          },
        ];
      }
    }
  );
  aggregateOneVariableBarChartData(parentsNotLoveFilteredData, dim).forEach(
    (d) => {
      // i have no clue what is going on with this ... needed to hack it and it sworks... casting whatev
      if (Object.keys(tempObj).includes(d.label as string)) {
        // replace old dummy object
        tempObj[String(d.label)][1] = {
          label: d.label,
          [d.label]: d[d.label],
        };
      } else {
        tempObj[d.label] = [
          {
            // we need to add the additional dummy element  if other liste doesn't have value
            label: d.label,
            [d.label]: 0,
          },
          {
            label: d.label,
            [d.label]: d[d.label],
          },
        ];
      }
    }
  );
  const orderLookup = getDataOrder(dim);
  const divergingMultiplier = Boolean(notDiverging) ? 1 : -1;
  const agg = Object.values(tempObj)
    .map(([plove, pnlove]) => {
      return {
        [`ILY`]: Boolean(plove) ? Number(plove[plove.label]) : 0,
        [`No ILY`]: Boolean(pnlove)
          ? divergingMultiplier * Number(pnlove[pnlove.label])
          : 0,
        label: plove.label,
      };
    })
    .sort((a, b) => {
      return orderLookup[a.label] - orderLookup[b.label];
    });
  return agg;
}

export const ETHNICITY_INDEX_START = 22;
export function aggregateDivergingBarEthnicity(data: Array<IAsianParentDatum>) {
  const allEthnicitiesWithResponses = Object.keys(data[0])
    .splice(ETHNICITY_INDEX_START)
    .sort() as Array<keyof IAsianParentDatum>;
  const parentsLoveFilteredData = data.filter((d) => d.parentsLove === "Yes");
  const parentsNotLoveFilteredData = data.filter((d) => d.parentsLove === "No");
  return allEthnicitiesWithResponses.map((e) => {
    return {
      label: e,
      ILY: parentsLoveFilteredData.filter((d) => d[e]).length,
      "No ILY": parentsNotLoveFilteredData.filter((d) => d[e]).length,
    };
  });
}

export function aggregateDivergingBarEthnicity2(
  data: Array<IAsianParentDatum>
) {
  const allEthnicitiesWithResponses = Object.keys(data[0])
    .splice(ETHNICITY_INDEX_START)
    .sort() as Array<keyof IAsianParentDatum>;
  const parentsLoveFilteredData = data.filter((d) => d.fatherLove !== "Never");
  const parentsNotLoveFilteredData = data.filter(
    (d) => d.fatherLove === "Never"
  );
  return allEthnicitiesWithResponses.map((e) => {
    return {
      label: e,
      ILY: parentsLoveFilteredData.filter((d) => d[e]).length,
      "No ILY": parentsNotLoveFilteredData.filter((d) => d[e]).length,
    };
  });
}

export function aggregateDivergingBarFatherLove(
  data: Array<IAsianParentDatum>,
  dim: keyof IAsianParentDatum
) {
  const parentsLoveFilteredData = data.filter((d) => d.fatherLove !== "Never");
  const parentsNotLoveFilteredData = data.filter(
    (d) => d.fatherLove === "Never"
  );
  //   {
  //     [k: string]: Array<{ label: string } & { [v: string]: number }>;
  //   }
  const tempObj = {} as _.Dictionary<BarDatum[]>;
  aggregateOneVariableBarChartData(parentsLoveFilteredData, dim).forEach(
    (d) => {
      if (d[d.label] in tempObj) {
        console.log("???");
      } else {
        tempObj[d.label] = [
          {
            label: d.label,
            [d.label]: d[d.label],
          },
          {
            // this is a dummy, in case other list doesn't have value
            label: d.label,
            [d.label]: 0,
          },
        ];
      }
    }
  );
  aggregateOneVariableBarChartData(parentsNotLoveFilteredData, dim).forEach(
    (d) => {
      // i have no clue what is going on with this ... needed to hack it and it sworks... casting whatev
      if (Object.keys(tempObj).includes(d.label as string)) {
        // replace old dummy object
        tempObj[String(d.label)][1] = {
          label: d.label,
          [d.label]: d[d.label],
        };
      } else {
        tempObj[d.label] = [
          {
            // we need to add the additional dummy element  if other liste doesn't have value
            label: d.label,
            [d.label]: 0,
          },
          {
            label: d.label,
            [d.label]: d[d.label],
          },
        ];
      }
    }
  );

  return Object.values(tempObj).map(([plove, pnlove]) => {
    return {
      [`ILY`]: Boolean(plove) ? Number(plove[plove.label]) : 0,
      [`No ILY`]: Boolean(pnlove) ? -1 * Number(pnlove[pnlove.label]) : 0,
      label: plove.label,
    };
  });
}

export function colorShade(col: string, amt: number) {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  const match = col.match(/.{2}/g);
  if (match) {
    let [r, g, b] = match;
    let [r2, g2, b2] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt,
    ];

    r = Math.max(Math.min(255, r2), 0).toString(16);
    g = Math.max(Math.min(255, g2), 0).toString(16);
    b = Math.max(Math.min(255, b2), 0).toString(16);

    const rr = (r.length < 2 ? "0" : "") + r;
    const gg = (g.length < 2 ? "0" : "") + g;
    const bb = (b.length < 2 ? "0" : "") + b;

    return `#${rr}${gg}${bb}`;
  }
  throw new Error("guh");
}

export const CUSTOM_NIVO_THEME = {
  fontSize: 10,
  fontFamily: "'Fira Code'",
  axis: {
    legend: {
      text: {
        fontSize: 11,
      },
    },
  },
  legends: {
    ticks: {
      text: {
        fontSize: 9,
      },
    },
  },
};
